.modal {
	max-height: calc(var(--100vh) * 0.85);
}

.wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.wrapper form {
	display: flex;
	height: 100%;
	flex-direction: column;
}

.wrapper form label {
	margin-bottom: 10px;
}

.wrapper form > button {
	margin-top: auto;
}

.newConsumerModalHeader {
	display: flex;
	justify-content: space-between;
}

.newConsumerModalInputField {
	width: 100%;
	height: 2.625em;
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border: 0;
	padding-left: 10px;
	border-radius: 6px;
}

.newConsumerModalInputField:focus {
	outline: 0.7px solid var(--chosen-border-color);
}

.newConsumerButton {
	background-color: var(--accent-color);
	border-radius: 40px;
	border: 0;
	height: 42px;
	width: 164px;
}

.newConsumerButton:hover {
	background-color: var(--accent-hover-color);
	cursor: pointer;
}

.newConsumerButtonText {
	font-size: 16px;
	font-weight: 600;
}

.newConsumerButtonContent {
	display: flex;
	justify-content: space-around;
	vertical-align: middle;
	color: var(--accent-inside-color);
}

.newConsumerButton:hover .newConsumerButtonContent {
	color: var(--accent-hover-inside-color);
}

.alreadyExistingConsumerLink {
	cursor: pointer;
	text-decoration: underline;
	font-weight: 600;
}

@media (max-width: 710px) {
	.modal {
		height: calc(var(--100vh) - 60px);
		max-height: unset;
	}
}
