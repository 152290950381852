/* The switch - the box around the slider */
.switch {
	position: relative;
	min-width: 50px;
	height: 24px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	width: 50px;
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--disable-color);
	-webkit-transition: 0.4s;
	transition: 0.4s;
	display: flex;
	align-items: center;
	padding-left: 4px;
}

.slider:before {
	position: absolute;
	content: "";
	height: 24px;
	width: 24px;
	left: 0;
	bottom: 0;
	background-color: var(--disable-inside-color);
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: var(--finalize-color);
}

input:checked + .slider:before {
	background-color: var(--finalize-inside-color);
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

/* Rounded sliders */
.slider.round {
	border-radius: 26px;
}

.slider.round:before {
	border-radius: 50%;
}

.iconColor {
	color: var(--finalize-inside-color);
}

.text {
	margin-left: 60px;
	user-select: none;
}

.showTextAbove .text {
	margin-left: 0;
	margin-bottom: 42px;
}

@media (max-width: 950px) {
	.showTextAbove .text {
		font-size: 14px;
		white-space: nowrap;
	}
}
